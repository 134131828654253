import React, { useRef } from 'react'
import {Helmet} from 'react-helmet'
import useFireWork from "react-use-firework"

import Terminal from '../../components/Terminal/Terminal'
import TerminalBar from '../../components/Terminal/TerminalBar'

import style from './style.module.sass'

const FoundationView = ({pageTitle, editMode, viewMode, components}) => {
  const ref = useRef(null)
  const foundationStyle = editMode ? style.foundationInEditMode : style.foundation

  useFireWork(ref,{
    count: Math.floor(Math.random() * 20) + 10,
    duration:800,
    size: Math.floor(Math.random() * 20) + 8,
    range: Math.floor(Math.random() * 600) + 100,
    colors: ['#990f3d','#f2dfce']
  })

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {(!viewMode)&&
          <React.Fragment>
            {editMode ? <Terminal /> : <TerminalBar />}
          </React.Fragment>}
      <div className={foundationStyle} ref={ref}>
        {components.map((component, index) => <React.Fragment key={index}>{component}</React.Fragment>)}
      </div>
    </div>
  )
}

export default FoundationView
