const MyReducer = (state, action) => {

  switch (action.type) {

    case "TOGGLE_EDIT_MODE":
    return {...state, editMode:action.payload}

    case "SET_VIEW_MODE":
    return {...state, viewMode:action.payload}

    case "SET_MYCOMMAND":
    return {...state, myCode:action.payload}

    case "SET_USER":
        return {...state, user:action.payload}

    case "SET_LOADING":
        return {...state, loading:action.payload}

    case "SET_LOG":
        return {...state, log:action.payload}

    default:
        return state

  }
}

export default MyReducer
