import React, {useContext, useState, useEffect} from 'react'

import MyContext from '../../contexts/MyContext'
import {cloudStorage, lorde} from '../../services/api'

import ClickToCopy from "./ClickToCopy"
import style from './style.module.sass'

const SignInModule = () => {
  const {
    user,
    myCode,
    log,
    setLog,
    setUser,
    loading,
    setLoading,
    viewMode
  } = useContext(MyContext)

  const [userID, setUserID] = useState('')
  const [userToken, setUserToken] = useState('')
  const [confirmDel, setConfirmDel] = useState(false)
  const [editLoopMode,setEditLoopMode] = useState(false)
  const [edited,setEdited] = useState(false)
  const [loop, setLoop] = useState(null)
  const [tab, setTab] = useState('dashboard')

  if (viewMode)
    return null

  const DashboardSection = () => {
    return (<div className={ style.inputForm }>
      <h1>{ user.name }</h1>
      <p>
        <span className={ style.button } onClick={ () => setTab('me') }>Browse All Notes</span>
      </p>
      <p>
        <span className={ style.button } onClick={ () => setTab('note') }>Page Setup</span>
      </p>
      <p>
        <span className={ style.button } onClick={ () => cloudStorage.SignOut(setUser) }>Sign Out</span>
      </p>
    </div>)
  }

  const LoopEditSection = ({loop}) => {
    const [edit,setEdit] = useState(loop)

    const saveLoopLocally = (editedLoop) => {
      const newLoop = []
      for (let i = 0; i < user.loop.length; i++) {
        if(user.loop[i].id===loop.id){
          newLoop.push(editedLoop)
        }else{
          newLoop.push(user.loop[i])
        }
      }
      setUser({...user,loop:newLoop.sort((a,b)=>Number(a.priority)-Number(b.priority))})
      setEdit(editedLoop)
      setEdited(true)
    }

    return (<div className={style.inlineOptions}>
        <input
          autoFocus
          type="text"
          placeholder={ lorde() }
          value={ edit.name }
          onChange={ e => saveLoopLocally({...edit, name: e.target.value})}
        />
        <input
          className={style.priorityInput}
          type="text"
          value={ edit.priority }
          onChange={ e => saveLoopLocally({...edit, priority: e.target.value})}
        />
    </div>)
  }

  const MeSection = () => {
    if (loop) {
      // log list
      return (<div className={ style.inputForm }>
        <h1>{ loop.info.name }</h1>
        <p>
          <span className={ style.button } onClick={ () => setLoop(null) }>Back</span>
        </p>
        {
          loop.logs.map(value => <a key={ value._id } target='_blank' href={ '/' + value._id } rel="noreferrer">
            <p>
              <span className={ style.link }>{ value.title }</span>
            </p>
          </a>)
        }
      </div>)
    } else {
      // loop list
      return (<div className={ style.inputForm }>
        <h1>{ user.name }</h1>
        {editLoopMode
          ?<div className={style.inlineOptions}>
            <p>
              <span className={ style.button } onClick={ () => {
                  if(edited)cloudStorage.SignIn(user._id, user.token, setUser, setLoading)
                  setEditLoopMode(false)
                } }>Exit</span>
            </p>
            {edited&&
              <p>
                <span className={ style.button } onClick={ () => {
                    cloudStorage.saveLoop(user, setUser, setLoading, setEdited)
                  } }>Save</span>
              </p>
            }
          </div>
          :<div className={style.inlineOptions}>
            <p>
              <span className={ style.button } onClick={ () => setTab('dashboard') }>Back</span>
            </p>
            <p>
              <span className={ style.button } onClick={ () =>{
                  const newLoop = []
                  for (let i = 0; i < user.loop.length; i++) {
                    if(i===0){
                      newLoop.push({...user.loop[i],edit:true})
                    }else{
                      newLoop.push({...user.loop[i],edit:false})
                    }
                  }
                  setUser({...user,loop:newLoop})
                  setEditLoopMode(true)
                } }>Edit</span>
            </p>
          </div>
        }
        {!editLoopMode
          ?user.loop.map(value =><p key={ value.id }>
              <span className={ style.button } onClick={ () => cloudStorage.getLoop(user, setUser, value.id, setLoop, setLoading) }>{ value.name }</span>
            </p>)
          :editLoopMode&&
            user.loop.map(value => value.edit
                ?<LoopEditSection key={ value.id } loop={value}/>
                :<p key={ value.id }>
                  <span className={ style.buttonOption } onClick={ () => {
                    const newLoop = []
                    for (let i = 0; i < user.loop.length; i++) {
                      if(user.loop[i].id===value.id){
                        newLoop.push({...user.loop[i],edit:true})
                      }else{
                        newLoop.push({...user.loop[i],edit:false})
                      }
                    }
                    setUser({...user,loop:newLoop})
                    }}>{ value.name }</span>
                </p>)
        }
      </div>)
    }
  }

  const DeleteButton = () => {
    if (confirmDel) {
      setTimeout(() => {
        setConfirmDel(false)
      }, 1000)
      return <span className={ style.button } onClick={ () => cloudStorage.erase(user, log._id, setLoading) }>Click Again to Confirm</span>
    } else {
      return <span className={ style.button } onClick={ () => setConfirmDel(true) }>Delete</span>
    }
  }

  const Loading = () =>{
      return (<div>
        <h1>Loading...</h1>
        <p>
          <span className={ style.button }>Cancel</span>
        </p>
      </div>)
  }

  if (loading) {
    return <Loading />
  } else {
    if (user) {
      if (tab === 'dashboard') {
        return <DashboardSection/>
      } else if (tab === 'me') {
        return <MeSection/>
      } else if (tab === 'note') {
        if (log._id) {
          // update note
          return (<div className={ style.inputForm }>
            <h1>{ lorde() }</h1>
            <p>
              <span className={ style.button } onClick={ () => setTab('dashboard') }>Back</span>
            </p>
            <div className={style.inlineOptions}>
              {user.loop.map(value =><p key={ value.id }>
                  <span
                    className={ value.id===log.loopID?style.button:style.buttonOption }
                    onClick={ () => cloudStorage.push(user, {...log,loopID:value.id}, myCode, setLoading,-1, setLog) }>
                    { value.name }</span>
                </p>)}
            </div>
            <p>
              <input type="text"
                value={ log.title }
                onBlur={ () => cloudStorage.push(user, log, myCode, setLoading,-1, setLog) }
                onChange={ e => {
                  setLog({
                    ...log,
                    title: e.target.value
                  })
                } }/>
            </p>
            {
              log.bar && <React.Fragment>
                  <ClickToCopy payload={ ['https://note.myprivaterelay.com/' + log.bar] }/>
                  <p>
                    <span className={ style.button } onClick={ () => cloudStorage.push(user, log, myCode, setLoading, 0, setLog) }>Unshare</span>
                  </p>
                </React.Fragment>
            }
            {
              !log.bar && <p>
                  <span className={ style.button } onClick={ () => cloudStorage.push(user, log, myCode, setLoading, 100, setLog) }>Share</span>
                </p>
            }
            <p>
              <DeleteButton/>
            </p>
          </div>)
        } else {
          // new note
          return (<div className={ style.inputForm }>
          <h1>{ lorde() }</h1>
            <p>
              <span className={ style.button } onClick={ () => setTab('dashboard') }>Back</span>
            </p>
            <div className={style.inlineOptions}>
              {user.loop.map(value=><p key={ value.id }>
                  <span
                    className={ value.id===log.loopID?style.button:style.buttonOption }
                    onClick={ () => setLog({...log,loopID:value.id}) }>
                    { value.name }
                  </span>
                </p>)}
            </div>
            <p>
              <input
                type="text"
                placeholder={ lorde() }
                value={ log.title }
                onChange={ e => setLog({...log, title: e.target.value})
              }/>
            </p>
            <p>
              <span className={ style.button } onClick={ () => cloudStorage.push(user, log, myCode, setLoading, -1) }>Upload</span>
            </p>
          </div>)
        }
      }
    } else {
      // log in
      return (<div className={ style.inputForm }>
        <h1>🖼 Bonjour</h1>
        <p>
          <input type="text" placeholder="ID" value={ userID } onChange={ e => setUserID(e.target.value) }/>
        </p>
        <p>
          <input type="text" placeholder="Password" value={ userToken } onChange={ e => setUserToken(e.target.value) }/>
        </p>
        <p>
          <span className={ style.button } onClick={ () => cloudStorage.SignIn(userID, userToken, setUser, setLoading) }>Sign In</span>
        </p>
      </div>)
    }
  }
}

export default SignInModule
