import React,{useState} from 'react'

import ClickToCopy from "./ClickToCopy"

const calculateAddresses = () => {
  const addresses = []
  let digitPos = []
  const letter = 'abdehmnkrtuwxy'
  const number = '2346789'

  while (digitPos.length<3) {
    const pos = Math.floor(Math.random() * 9 + 1)
    if (!digitPos.includes(pos)){
      digitPos.push(pos)
    }
  }

  for (let i = 0; i < 2; i++) {
    let address = ''

    for (let ii = 0; ii < 10; ii++) {
      if(digitPos.includes(ii)){
        address += number.charAt(Math.floor(Math.random() * number.length))
      } else {
        address += letter.charAt(Math.floor(Math.random() * letter.length))
      }
    }

    const domain = i % 2 == 0 ? '@myprivaterelay.com' : '@yihaolou.com'
    addresses.push(address+domain)
  }
  return addresses
}

const MyPrivateRelayModule = () => {

  const [addresses,setAddresses] = useState(calculateAddresses())

  function changeAddresses(){
    setAddresses(calculateAddresses())
  }

  return (
    <div onClick={changeAddresses}>
        <h1>📮 MyPrivateRelay.com</h1>
        <ClickToCopy payload={addresses} />
    </div>
  )
}

export default MyPrivateRelayModule
