import React from 'react'

import earthVideo from '../../assets/earth.mp4'
import style from './style.module.sass'

const EarthModule = () => {

return (
    <div className={style.homer}>
        <video autoPlay loop muted width="100%">
            <source src={earthVideo} type="video/mp4" />
        </video>
    </div>
  )
}

export default EarthModule
