import React,{useState,useEffect} from 'react'

import style from './style.module.sass'

import Suggestions from "./Suggestions"

const InvalidCommandModule = ({commandLine}) => {

  const [typing,setTyping]=useState(true)

  useEffect(()=>{
    var typingStatus=setInterval(()=>setTyping(false),2000)
    return () => window.clearInterval(typingStatus)
  },[typing])

  if( typing ){
    return (<div className={style.typing}>
        <div />
        <div />
        <div />
        <div />
        </div>)
  }else{
    return (
        <React.Fragment>
          <div>
            <h1>OOPS...You stopped the line</h1>
            <p>
                <code>{commandLine}</code>
                {' '}
                is not working.
            </p>
          </div>
          <Suggestions />
        </React.Fragment>
    )
  }
}

export default InvalidCommandModule
