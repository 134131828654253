import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import style from './style.module.sass'

const ClickToCopyModule = ({payload}) => {

  const content = payload.map((command,index)=> {
      if( command ) {
          return (
              <CopyToClipboard
                  key = {"CLICKTOCOPY"+index.toString()}
                  text={command}
              >
                  <p>
                    <span className={style.clickableLine}>
                      {command}
                    </span>
                  </p>
              </CopyToClipboard>
          )
      }else{
          return null
      }
  })

  return (
    <div>
      {content}
    </div>
  )
}

export default ClickToCopyModule
