import React from 'react'

import homerGIF from '../../assets/homer.gif'

import style from './style.module.sass'

const HomerModule = () => {

  return (
    <div className={style.homer}>
       <img alt="Summer time" src={homerGIF} />
    </div>
  )
}

export default HomerModule
