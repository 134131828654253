import React,{useState,useEffect} from 'react'

import style from './style.module.sass'

const TimeModule = () => {

  const now = new Intl.DateTimeFormat('en-AU').format(new Date())

  return (
    <div className={style.timeBox}>
      {now}
    </div>
  )
}

export default TimeModule
