import React,{useState,useEffect} from 'react'

import style from './style.module.sass'

const BCHeaderModule = () => {
  const count = Math.ceil((new Date() - new Date(2021, 7, 2)) / (7 * 24 * 60 * 60 * 1000));

  return (
    <div className={style.markdown} style={{marginBottom:'0'}}>
      <h1>🥷🏻 BC Dashboard - Week {count}</h1>
    </div>
  )
}

export default BCHeaderModule
