import React,{ useContext } from 'react'

import MyContext from '../../contexts/MyContext'
import {cloudStorage} from "../../services/api"

import style from './style.module.sass'

const Terminal = () => {
  const {user,log,myCode,setMyCode,toggleEditMode,setLoading,setLog,loading} = useContext(MyContext)

  const handleChange = (event) => {
    const code=event.target.value
    setMyCode(code)
    cloudStorage.localPush(log._id,code)
  }

  const closeAndPush=async ()=>{
      if(user&&log._id) await cloudStorage.push(user,log,myCode,setLoading,-1,setLog)
      toggleEditMode()
  }
  return (
    <div className={loading?style.terminal_loading:style.terminal}>
      <svg aria-label="Close" className={style.closeButton} onClick={closeAndPush} viewBox="0 0 24 24">
        <path d="m3 3 18 18M3 21 21 3" fill="none" stroke="#000" strokeWidth="2" />
      </svg>
      <h1> </h1>
      <textarea onChange={handleChange} value={myCode} />
      <h1 />
    </div>
  )
}

export default Terminal
