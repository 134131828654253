import React from 'react'
import {BrowserRouter,Routes,Route} from "react-router-dom"

import './styles/_global.sass'
import './styles/_typography.sass'

import EmailRelay from "./pages/App/emailRelay"
import Foundation from './pages/Foundation/Foundation'
import SecondFoundation from './pages/Foundation/SecondFoundation'

const App = () => {
    return (
      <BrowserRouter>
        <Routes>
          <Route element={<EmailRelay />} path="/email" />
          <Route element={<SecondFoundation />} path="/:_logID" />
          <Route element={<Foundation />} path="*" />
        </Routes>
      </BrowserRouter>)
}

export default App
