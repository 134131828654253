import React, {useContext,useEffect} from 'react'
import {useParams} from "react-router-dom"

import {analyst, engineer, cloudStorage} from '../../services/api'
import MyContext from '../../contexts/MyContext'

import FoundationView from './FoundationView'

const SecondFoundation = () => {

  const {user,log,myCode,viewMode,editMode,setLog,setMyCode,setLoading,setViewMode} = useContext(MyContext)

  const params = useParams()
  const _logID = params._logID

  const loadingLog = async () =>{
    let _log
    if(_logID.length===3){
      _log = await cloudStorage.getShareLog(_logID,setLoading)
    }else{
      _log = await cloudStorage.getLog(user,_logID,setLoading,setViewMode)
      cloudStorage.localPush(_logID,_log.content)
    }
    setLog(_log)
    setMyCode(_log.content)
  }

  useEffect(()=>{
    const localCopy = cloudStorage.getLocalCode(_logID)
    if(localCopy) setMyCode(localCopy)
    loadingLog()
  },[])

  const components = engineer(analyst(myCode))
  const props = {editMode,viewMode,pageTitle:log.title,components}

  return <FoundationView {...props} />
}

export default SecondFoundation
