import React from 'react'

import ClickToCopy from "./ClickToCopy"

const SuggestionsModule = () => {

    const sampleCommands = [
        'OK, BC! Show me the Time',
        'OK, BC! Summer Time',
        'OK, BC! Energize',
    ]

  return (
    <div>
        <h1>🥷🏻Try typing</h1>
        <ClickToCopy payload={sampleCommands} />
    </div>
  )
}

export default SuggestionsModule
