import axios from 'axios'

import demoProfile from './profile/demo'
import {lorde} from './api'

// const SERVER = 'http://localhost:5000'
const SERVER = 'https://api.myprivaterelay.com'
const log404 = {title:'Oops...',content:"# "+lorde()+"\n404 Error - Page Not Found."}

const getLocalValue=name=>{
  return localStorage[name]?JSON.parse(localStorage[name]):null
}
const setLocalValue=(name,value)=>{
  localStorage[name]=JSON.stringify(value)
}

const getLocalUser=()=>{
  const user=getLocalValue('user')
  return user?user:null
}

const getLocalCode=(logID)=>{
  const localStorage=getLocalValue(logID)
  if(localStorage){
    return localStorage
  }else{
    if(logID==='myCode'){
      return demoProfile
    }else{
      return null
    }
  }
}

const localPush=(logID,code)=>{
  setLocalValue(logID?logID:'myCode',code)
}

const SignIn=(userID,userToken,setUser,setLoading)=>{
  console.log("jjj");
  setLoading(true)
  axios.post(SERVER+'/get/user',{
    userID:userID,
    userToken:userToken,
  }).then(function(response){
    console.log(response);
    setLoading(false)
    if(response.data.user){
      console.log(response.data.user);
      setLocalValue('user',response.data.user)
      setUser(response.data.user)
    }
  }).catch(function(error){
    console.log(error);
  });
}

const SignOut=setUser=>{
  setUser(null)
  localStorage.clear()
}

const getLoop=(user,setUser,loopID,setLoop,setLoading)=>{
  setLoading(true)
  axios.post(SERVER+'/get/loop',{
    userID:user._id,
    token:user.token,
    loopID:loopID,
    skip:0
  }).then(function(response){
    setLoading(false)
    for (let i = 0; i < user.loop.length; i++) {
      if(user.loop[i].id === loopID){
        setLocalValue('user',response.data.user)
        setUser(response.data.user)
        setLoop({
          info:user.loop[i],
          logs:response.data.loop,
        })
        break
      }
    }
  }).catch(function(error){
    console.log(error);
  });
}


const getLog=async (user,logID,setLoading,setViewMode)=>{
  if(user){
    setLoading(true)
    const response=await axios.post(SERVER+'/get/log',{
      userID:user._id,
      token:user.token,
      logID:logID,
    })
    setLoading(false)
    if(response.data && response.data.log){
      setViewMode(false)
      return response.data.log
    }
  }
  return log404
}

const getShareLog=async (bar,setLoading)=>{
  setLoading(true);
  const response=await axios.post(SERVER+'/get/share',{
    bar:bar
  })
  setLoading(false)
  return response.data.log?response.data.log:log404
}

const push = async (user,log,myCode,setLoading,shareCount,setLog) => {
  if(!user)return
  setLoading(true)
  const response = await axios.post(SERVER+'/push',{
    userID:user._id,
    token:user.token,
    logID:log._id,
    loopID:log.loopID,
    title:log.title,
    content:myCode,
    barCount:shareCount,
  })
  if(log._id){
    setLoading(false)
    setLog({...log,...response.data.log})
  }else{
    window.location.href = `/${response.data.log._id}`
  }
}

const erase = (user,logID,setLoading) => {
  setLoading(true)
  axios.post(SERVER+'/delete/log',{
    userID:user._id,
    token:user.token,
    logID:logID,
  }).then(function(){
    window.location.href = `/`
  }).catch(function(error){
    console.log(error)
  })
}

const saveLoop = (user,setUser,setLoading,setEdited) => {
  setLoading(true)
  const newLoop = []
  for (let i = 0; i < user.loop.length; i++) {
    newLoop.push({
      id:user.loop[i].id,
      name:user.loop[i].name,
      priority:user.loop[i].priority,
      begin:user.loop[i].begin,
    })
  }
  axios.post(SERVER+'/update/loop',{
    userID:user._id,
    token:user.token,
    loop:newLoop,
  }).then(function(response){
    setLoading(false)
    setEdited(false)
  }).catch(function(error){
    console.log(error);
  })
}

export default {
  SignIn,
  SignOut,
  getLocalUser,
  getLocalCode,
  getLoop,
  getLog,
  getShareLog,
  push,
  localPush,
  saveLoop,
  erase,
}
