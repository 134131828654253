import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import style from './style.module.sass'

const TextModule = ({payload}) => {

  const content = payload.join("\n").toString()

  return (
    <div className={style.markdown}>
        {/* eslint-disable-next-line react/no-children-prop */}
       <ReactMarkdown children={content} remarkPlugins={[remarkGfm]} />
    </div>
  )
}

export default TextModule
