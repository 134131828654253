import React from 'react'

import ClickToCopyModule from '../../components/module/ClickToCopy'
import CloudModule from '../../components/module/Cloud'
import EndingModule from '../../components/module/Ending'
import HomerModule from '../../components/module/Homer'
import InvalidCommandModule from '../../components/module/InvalidCommand'
import ImageModule from '../../components/module/Image'
import Suggestions from '../../components/module/Suggestions'
import TextModule from '../../components/module/MarkDownText'
import TimeModule from '../../components/module/Time'
import LoadingModule from '../../components/module/Loading'
import MyPrivateRelayModule from '../../components/module/MyPrivateRelay'
import EarthModule from '../../components/module/Earth'
import BCHeaderModule from '../../components/module/BCHeader'

const engineer = requirements => {
  if(!requirements) return []

  let HomerIsComing = false
  let SolarPowerIsComing = false
  let deliverables = []

  for (let i = 0; i < requirements.length; i++) {
    const ticket = requirements[i]
    switch (ticket.name) {
      case 'INVALIDCOMMAND':
        deliverables.push(<InvalidCommandModule commandLine={ticket.payload} />)
      break;

      case 'BCHEADER':
        deliverables.push(<BCHeaderModule />)
        break;


      case 'CHECKITOUT':
        localStorage.clear()
        window.location.reload()
      break;

      case 'SUMMERTIME':
        HomerIsComing = true
        break;

      case 'SOLARPOWER':
        SolarPowerIsComing = true
        break;

      case 'SUGGESTIONS':
        deliverables.push(<Suggestions />)
        break;

      case 'SHOWMETHETIME':
        deliverables.push(<TimeModule />)
      break;

      case 'ENERGIZE':
        deliverables.unshift(<EndingModule />)
      break;

      case 'TEXT':
        deliverables.push(<TextModule payload={ticket.payload} />)
      break;

      case 'IMAGE':
        deliverables.push(<ImageModule payload={ticket.payload} />)
      break;

      case 'CLICKTOCOPY':
        deliverables.push(<ClickToCopyModule payload={ticket.payload} />)
      break;

      case 'MYPRIVATERELAY':
        deliverables.push(<MyPrivateRelayModule />)
      break;

      case 'LOADING':
        deliverables.push(<LoadingModule />)
      break;

      case 'CLOUD':
        deliverables.push(<CloudModule />)
      break;
    }
  }
  // console.log(deliverables.length,deliverables);
  if(HomerIsComing) deliverables.splice(Math.floor(Math.random() * ( deliverables.length -1 ) + 1), 0, <HomerModule />)
  if(SolarPowerIsComing) deliverables.splice(Math.floor(Math.random() *( deliverables.length - 1 ) + 1), 0, <EarthModule />)
  return deliverables
}

export default engineer
