import React,{ useContext } from 'react'

import MyContext from '../../contexts/MyContext'

import style from './style.module.sass'

const TerminalBar = () => {
  const { toggleEditMode } = useContext(MyContext)

  return (
    <div className={style.terminalBar} onClick={toggleEditMode}>
      <svg aria-label="open" className={style.openButton} viewBox="0 0 24 24"><path d="m7 2 10 10L7 22" fill="none" stroke="#000" strokeWidth="2" /></svg>
    </div>
  )
}

export default TerminalBar
