const analyst = text => {
  let requirements = []
  let currentCommand = 'TEXT'
  let payload = []

  if(!text) return null

  const lines = text.split('\n')
  lines.push('OK BC! BREAK')

  for (let i = 0; i < lines.length; i++) {
    const originalLine = lines[i]
    if(originalLine.length === 0) {
      payload.push('')
      continue
    }

    const commandLine = originalLine.toUpperCase().replace(/[^A-Z]/gi, '')
    // console.log(commandLine,payload);
    if(isCommand(commandLine)){
      const commandName = commandLine.slice(4)

      if(payload.length>0){
        requirements.push({name:currentCommand,payload})
        currentCommand = 'TEXT'
        payload = []
      }

      if(commandName === 'BREAK') continue

      if( isKnownCommand(commandName) ){
        if( isSingleLineCommand(commandName) ){
          requirements.push({name:commandName,payload:originalLine})
        }else{
          currentCommand = commandName
        }
      }else{
        requirements.push({name:'INVALIDCOMMAND',payload:originalLine})
      }
    }else{
      payload.push(originalLine)
    }
  }
  // console.table(requirements);
  return requirements
}

const singleLineCommands = ['LOADING','CLOUD','BREAK','SHOWMETHETIME','SUMMERTIME','ENERGIZE','CHECKITOUT','SUGGESTIONS','MYPRIVATERELAY','SOLARPOWER','BCHEADER']

const multiLinesCommands = ['IMAGE','TEXT','CLICKTOCOPY']

const isSingleLineCommand = command => {
  return (singleLineCommands.indexOf(command) > -1)
}

const isKnownCommand = command => {
  const knownCommands = [...singleLineCommands,...multiLinesCommands]
  return (knownCommands.indexOf(command) > -1)
}

const isCommand = anything => anything.slice(0, 4) === 'OKBC'

export default analyst
