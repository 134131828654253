import React from 'react'

import style from './style.module.sass'

const LoadingModule = () => {
  return <div className={style.typing}>
    <div />
    <div />
    <div />
    <div />
    </div>
    }

export default LoadingModule
