const lyrics = `Acid green, aquamarine
And I throw my cellular device in the water
Are you coming, my baby?
Blink three times when you feel it kicking in
But when the heat comes, something takes a hold
Can I kick it? Yeah, I can
Can you reach me? No, you can't (aha)
Come on and let the bliss begin
Come one, come all, I'll tell you my secrets
Forget all of the tears that you've cried
I hate the winter, can't stand the cold
I'm kinda like a prettier Jesus
It's a new state of mind
It's over (over, over, over)
It's solar (solar, solar, solar)
Lead the boys and girls onto the beaches
My boy behind me, he's taking pictures
My cheeks in high color, overripe peaches
No shirt, no shoes, only my features
Solar-olar-olar power
Solar-olar-olar power (oh)
That solar-olar-olar power
The girls are dancing in the sand
Turn it on in a new kind of bright`
const lorde = () =>{
    const lines = lyrics.split('\n')
    const randomLineNumber = Math.floor(Math.random() * lines.length)
    return lines[randomLineNumber]
}
export default lorde
