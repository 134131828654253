import React, {useContext, useEffect} from 'react'

import {analyst, engineer, lorde, cloudStorage } from '../../services/api'
import MyContext from '../../contexts/MyContext'

import FoundationView from './FoundationView'

const Foundation = () => {

  const {myCode,setMyCode,editMode,viewMode,setViewMode} = useContext(MyContext)

  useEffect(()=>{
    setMyCode(cloudStorage.getLocalCode('myCode'))
    setViewMode(false)
  },[])

  const pageTitle = lorde()
  const components = engineer(analyst(myCode))
  const props = {pageTitle,editMode,viewMode,components}

  return <FoundationView {...props} />
}

export default Foundation
