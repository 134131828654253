export default `# Introducing This Page
### Private Markdown notes with endless possibilities.
- **Markdown capability**, render as you type.
- **Fun & useful commands**, expect the unexpected.
- **Local data storage**, never upload to a server.

# GitHub Flavored Markdown
![Github](https://media.giphy.com/media/du3J3cXyzhj75IOgvA/giphy.gif)

All notes content will be rendered as React components by [react-markdown](https://github.com/remarkjs/react-markdown).
> Notes from Peng (15/12/2021): Due to time constraints, I created customized CSS styles for frequently used syntax first, such as headings, scripts, and blockquotes. The remainder is left for future work.

# Beyond Markdown
The first additional requirement for this page is providing a click-to-copy function. As a result, a user can manage and use various commands effortlessly.

To avoid adding extra syntax to Markdown, a user simply enters \`OK, BC!\` and the line will be regarded as a command. Currently, two types of commands are available:

- **Multi-line Command**: lines between an \`OK, BC! Click to Copy\` and any subsequent \`OK, BC!\` command will be converted into clickable buttons with a copy to clipboard function. It can also be ended with \`OK, BC! Break\`.
- **Single-line Command**: consider it as a magic spell.

![spell](https://media.giphy.com/media/RyLtUMBdogHvO/giphy.gif)

OK, BC! Suggestions

# 🥑 Yahoo in 2021
My [PR](https://github.com/pulls) | [Notifications](https://github.com/notifications) | [bower.json](https://github.com/bc-peng/bigcommerce/blob/master/bower.json) | [checkout-js](https://github.com/bc-peng/checkout-js) | [checkout-sdk-js](https://github.com/bc-peng/checkout-sdk-js)

BC [BCapp](https://github.com/bigcommerce/bigcommerce) | [checkout-js](https://github.com/bigcommerce/checkout-js) | [checkout-sdk-js](https://github.com/bigcommerce/checkout-sdk-js)

Launchbay [BCapp](https://launchbay.bigcommerce.net/projects/1/releases/new) | [checkout-sdk-js-server](https://launchbay.bigcommerce.net/projects/256/dashboard)

https://my-dev-store-926320498.store.bcdev/checkout

https://my-dev-store-926320498.store.bcdev/admin



# Frequently Used Commands

OK, BC! Click to Copy

WEBPACK_DONE='rsync -rvc --progress --delete --compress-level=9 build store.bcdev:/opt/bigcommerce_app/vagrant_code/vendor/bower_components/checkout/' npm run dev

rsync -rvc --progress --delete --compress-level=9 build store.bcdev:/opt/bigcommerce_app/vagrant_code/vendor/bower_components/checkout/;

./update
./bcloud clusters list
./bcloud dns setup
./bcloud rsync auto
./bcloud store upgrade 10000000
./bcloud bcapp bake
const consoleStyle = 'font-weight: bold; font-size: 50px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113); margin-bottom: 12px; padding: 5%'; console.log('%c Did somebody say KFC?', consoleStyle);
OK, BC! Text

# Recipe for Nuking Dev Store
OK, BC! Click to Copy
./bcloud clusters destroy
./bcloud clusters create
source ~/.dev_profile
./bcloud profiles activate
🙅🏻‍⏳ Wait for Maestro
./bcloud create store
./bcloud start shipping
./bcloud pull bigpay --restart
./bcloud start star-search && ./bcloud star star-indexer
./bcloud pull inventory --restart
./bcloud pull inventory-core --restart
🙅🏻‍⏳ BEFORE CODE RSYNC
🙆🏻‍ DELETE  \`known_hosts\`  IN  \`/Users/name/.ssh/\`
OK, BC! Text
# Made with ❤️ in Melbourne, 2021
This has been a BigCommerce Quarterly Sydney Hackathon project by Peng.`
