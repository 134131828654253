import React from 'react'

import style from './style.module.sass'

const singleImage = fileName => {
  return (
    <img key={fileName} alt={fileName} src={`https://share.myprivaterelay.com/${fileName}`} />
  )
}

const twoImages = fileName => {
  const fileNames = fileName.split('||')
  const leftImageUrl = fileNames[0].split('|')[1] ?? fileNames[0]
  const leftImageTitle = fileNames[0].split('|')[0] ?? ''
  const rightImageUrl = fileNames[1].split('|')[1] ?? fileNames[0]
  const rightImageTitle = fileNames[1].split('|')[0] ?? ''

  return (
    <>
      <ul>
        <h2>{leftImageTitle}</h2>
        <img key={leftImageUrl} alt={leftImageTitle} src={`https://share.myprivaterelay.com/${leftImageUrl}`} />
      </ul>
      <ul>
        <h2>{rightImageTitle}</h2>
        <img key={rightImageUrl} alt={rightImageTitle} src={`https://share.myprivaterelay.com/${rightImageUrl}`} />
      </ul>
    </>
  )
}

const ImageModule = ({payload}) => {
  return (
    <div className={style.image}>
      {payload.map(fileName=> fileName.includes('||') ? twoImages(fileName) :singleImage(fileName))}
    </div>
  )
}

export default ImageModule
