import React,{ useState,useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import discovery from '../../assets/discovery.jpg'
import foundation from '../../assets/foundation.jpg'
import down from '../../assets/down.jpg'
import happy from '../../assets/happy.jpg'
import startrek from '../../assets/startrek.gif'

import style from './style.module.sass'
import TimeModule from './Time'

const EndingModule = () => {

  const [counter,setCounter] = useState(0)
  const content = "* Powered by `react-markdown`.\n* Follows [GitHub Flavored Markdown](https://github.github.com/gfm/).\n* Renders actual React elements not `dangerouslySetInnerHTML`.\n# H1 Heading\n## H2 Heading\n### H3 Heading"

  useEffect(()=>{
    //if( counter === 5 ) toggleEditMode()
    setTimeout(()=>setCounter(counter+1),100)
  },[counter])

  return (
    <div className={style.ending}>
      {counter > 85 &&
        <TimeModule />}
      {counter > 80 &&
        <h1>Thanks for Watching</h1>}
      {counter > 70 &&
        <React.Fragment>
          <h1>Happy Holidays</h1>
          <img src={happy} />
        </React.Fragment>}
      {counter > 65 &&
        <h1>Made with ❤️ in Melbourne, 2021</h1>}
      {counter > 50 &&
        <React.Fragment>
          <h1>Fun & Useful Commands</h1>
          <h3>Limited Only by Imagination</h3>
          <img src={startrek} />
          <p><span className={style.clickableLine}>OK, BC! Click to Copy</span></p>
          <p><span className={style.clickableLine}>OK, BC! Show me the Time</span></p>
          <p><span className={style.clickableLine}>OK, BC! Summer Time</span></p>
          <p><span className={style.clickableLine}>OK, BC! Energize</span></p>
      </React.Fragment>}
      {counter > 25 &&
        <React.Fragment>
          <h1>Full Markdown Capability</h1>
          <img src={down} />
          <div className={style.markdown}>
            {/* eslint-disable-next-line react/no-children-prop */}
            <ReactMarkdown children={content} remarkPlugins={[remarkGfm]} />
          </div>
        </React.Fragment>}
      {counter > 10 &&
        <React.Fragment>
          <h1>This project is inspired by</h1>
          {counter > 15 &&
            <img src={foundation} />}
          <img src={discovery} />
        </React.Fragment>}
      <h1>Here Comes a 9-second Recap</h1>
    </div>
  )
}

export default EndingModule
