import React, { createContext, useReducer} from 'react'

import {cloudStorage} from "../services/api"
import MyReducer from './MyReducer'

const MyContext = createContext()
const MyContextProvider = ({ children }) => {
  const initialState = {
    myCode: 'OK,BC! Loading',
    editMode: false,
    viewMode: true,
    loading: false,
    user: cloudStorage.getLocalUser(),
    log:{_id:null, title:'', loopID:42},
  }

  const [state, dispatch] = useReducer(MyReducer, initialState)

  const setLoading = payload => {
    dispatch({type: 'SET_LOADING', payload })
  }

  const toggleEditMode = () => {
    dispatch({type: 'TOGGLE_EDIT_MODE', payload: !state.editMode })
  }
  const setViewMode = () => {
    dispatch({type: 'SET_VIEW_MODE', payload: !state.viewMode })
  }

  const setMyCode = payload => {
    dispatch({type: 'SET_MYCOMMAND', payload })
  }

  const setUser = payload => {
    dispatch({type: 'SET_USER', payload})
  }

  const setLog = payload => {
    dispatch({type: 'SET_LOG', payload})
  }

  return (
    <MyContext.Provider value={{toggleEditMode,setViewMode,setMyCode,setUser,setLoading,setLog,...state}}>
      {children}
    </MyContext.Provider>
   )
}
export default MyContext
export { MyContextProvider }
